<template>
  <v-container v-if="$role([], $store.state.user.role)" class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col v-if="false" cols="12" class="col-sm-3">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height secondary" dense dark outlined @click.prevent="$role([], $store.state.user.role) ? STORE_SHOW() : null">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Create New Vendor
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-6 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-6 white">
        <div class="pa-4">
          <v-select
            v-model="tableSearchType"
            :items="['All Type'].concat($store.state.types)"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Type"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="filteredData"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 15, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #DRQ/{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <div>
              {{ item.user_name }}
              <div class="caption">
                {{ item.user_email || '-' }}, {{ item.user_phone || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.name }}
                <div v-if="item.type === 'Doctors'" class="caption text-capitalize d-flex align-center">
                  <v-icon class="mr-1" small>
                    mdi-medication-outline
                  </v-icon>
                  {{ item.type_main === 'dr_spesialis' ? ('Dr Spesialis' + ' - ' + item.type_sub) : (item.type_main || '').replace('_', ' ') }}
                </div>
                <div v-if="parseInt(item.kecamatan_id)" class="caption text-capitalize d-flex align-center">
                  <v-icon class="mr-1" small>
                    mdi-map-marker
                  </v-icon>
                  {{ item.kecamatan_name + ', ' + item.city_type + ' ' + item.city_name + ', ' + item.province_name }}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div>
              {{ item.phone }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="STORE_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogStore.show"
      persistent
      scrollable
      max-width="475"
    >
      <v-card v-if="dialogStore.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogStore.data.id ? 'Update Vendor' : 'Create New Vendor' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: 90vh;">
          <v-row>
            <v-col cols="12" class="pr-3">
              <v-text-field
                v-model="dialogStore.data.name"
                outlined
                dense
                hide-details
                label="Name"
                class="mb-4 rounded-0"
              />
              <v-select
                :disabled="parseInt(dialogStore.data.id) ? true : false"
                v-model="dialogStore.data.type"
                :items="$store.state.types"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Type"
              />
              <v-select
                v-if="dialogStore.data.type === 'Doctors'"
                v-model="dialogStore.data.type_main"
                :items="$store.state.educations.main"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Educations"
              />
              <v-autocomplete
                v-if="dialogStore.data.type === 'Doctors' && dialogStore.data.type_main === 'dr_spesialis'"
                v-model.number="dialogStore.data.type_sub"
                :items="$store.state.educations.sub"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Select Specialist"
              />
              <v-text-field
                v-model.number="dialogStore.data.price"
                outlined
                dense
                hide-details
                label="Service Price"
                prefix="Rp"
                class="mb-4 rounded-0"
                @keypress="$NumOnly"
              />
              <v-autocomplete
                v-model="areaselected"
                :items="areas"
                :search-input.sync="areaquery"
                item-text="area_name"
                outlined
                dense
                return-object
                hide-details
                class="mb-4 rounded-0"
                label="Select Area"
              />
              <v-text-field
                v-model="dialogStore.data.phone"
                outlined
                dense
                hide-details
                label="No.HP / Whatsapp"
                class="mb-4 rounded-0"
                @keypress="$NumOnly"
              />
              <v-textarea
                v-model="dialogStore.data.description"
                outlined
                rows="4"
                dense
                hide-details
                label="About / Description"
                class="mb-4 rounded-0 body-2"
              />
              <v-select
                v-model.number="dialogStore.data.status"
                :items="[
                  { name: 'Active', value: 1 },
                  { name: 'Not Active', value: 0 }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Live / Published"
              />
            </v-col>
            <v-col cols="12" class="col-sm-6 pl-3">
              <div class="caption">
                Image
              </div>
              <div class="py-2 mb-2 d-flex fill-width" style="position: relative;">
                <v-img
                  :src="dialogStore.data.logo"
                  :aspect-ratio="2/1"
                  class="purple lighten-5 rounded p-relative"
                  style="max-height:80px;"
                >
                  <div class="d-flex fill-width fill-height align-center justify-center caption text-center c-pointer" style="position: absolute;top: 0px;left:0px;" @click.prevent="UploadImage()">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      {{ dialogStore.data.logo ? 'Change Client Logo' : 'Upload Client Logo' }}
                    </span>
                  </div>
                </v-img>
              </div>
            </v-col>
            <v-col v-if="false" cols="12" class="col-sm-6 pl-3">
              <div class="caption">
                Logo
              </div>
              <div class="py-2 mb-2 d-flex fill-width" style="position: relative;">
                <v-img
                  :src="dialogStore.data.logo"
                  :aspect-ratio="2/1"
                  class="purple lighten-5 rounded p-relative"
                  style="max-height:80px;"
                >
                  <div class="d-flex fill-width fill-height align-center justify-center caption text-center c-pointer" style="position: absolute;top: 0px;left:0px;" @click.prevent="UploadImage()">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      {{ dialogStore.data.logo ? 'Change Client Logo' : 'Upload Client Logo' }}
                    </span>
                  </div>
                </v-img>
              </div>
              <div class="caption">
                Banner
              </div>
              <div class="py-2 mb-2 d-flex fill-width justify-center" style="position: relative;">
                <v-img
                  :src="dialogStore.data.banner"
                  :aspect-ratio="2/1"
                  class="purple lighten-5 rounded p-relative fill-width"
                  style="max-height:120px;"
                >
                  <div class="d-flex fill-width fill-height align-center justify-center caption text-center c-pointer" style="position: absolute;top: 0px;left:0px;" @click.prevent="UploadImage('banner')">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      {{ dialogStore.data.banner ? 'Change Client Banner' : 'Upload Client Banner' }}
                    </span>
                  </div>
                </v-img>
              </div>
              <div class="caption">
                Galleries
              </div>
              <div class="py-2 mb-4 d-flex fill-width justify-center" style="position: relative;">
                <v-row>
                  <v-col cols="6" class="col-sm-4 p-relative">
                    <div class="fill-width grey lighten-5 rounded-lg" style="padding-bottom: 100%;" />
                    <div class="fill-width fill-height d-flex align-center justify-center c-pointer" style="position: absolute;top:0px;left:0px;" @click.prevent="ProcessGallery()">
                      <v-icon color="secondary lighten-2" class="">
                        mdi-plus
                      </v-icon>
                    </div>
                  </v-col>
                  <v-col v-for="(g, iG) in dialogStore.data.galleries" :key="'store-gallery-' + iG" cols="6" class="col-sm-4 p-relative">
                    <v-img
                      :aspect-ratio="1/1"
                      :lazy-src="g.src || '/no-image.jpg'"
                      :src="g.src || '/no-image.jpg'"
                      class="purple lighten-5 rounded fill-width"
                    />
                    <div class="fill-width fill-height d-flex align-center justify-center" style="position: absolute;top:0px;left:0px;">
                      <v-icon small color="secondary lighten-2" class="mr-1" @click.prevent="ProcessGallery(g, iG)">
                        mdi-reload
                      </v-icon>
                      <v-icon small color="red lighten-2" class="ml-1" @click.prevent="ProcessGallery('delete', iG)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogStore.show = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="false"
            color="green darken-1"
            text
            @click="STORE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
// let searchInterval = null
let searchAreaTimeout = null
export default {
  name: 'StoreManager',
  data: () => ({
    headers: [
      {
        text: 'Vendor ID',
        value: 'id',
        sortable: false
      },
      { text: 'Name', value: 'name', sortable: true },
      { text: 'Account', value: 'user_name', sortable: true },
      { text: 'Type', value: 'type', sortable: true },
      { text: 'Status', value: 'status', align: 'center', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    tableSearchType: 'All Type',
    options: {},
    dialogStore: {
      show: false,
      data: null
    },
    areaquery: '',
    areaselected: null,
    areas: [],
    loadingImageUpload: false
  }),
  computed: {
    filteredData () {
      return this.table.filter((r) => {
        let rtr = true
        if (this.tableSearchType && this.tableSearchType !== 'All Type') {
          rtr = r.type === this.tableSearchType
        }
        if (this.tableSearch) {
          const q = new RegExp(this.tableSearch.toLowerCase(), 'gi')
          rtr = (r.name + r.type + r.phone).toLowerCase().match(q)
        }
        return rtr
      })
    },
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    // tableSearch: function (q) {
    //   if (searchInterval) {
    //     clearTimeout(searchInterval)
    //   }
    //   searchInterval = setTimeout(() => {
    //     this.STORE_GET(q)
    //   }, 300)
    // },
    // tableSearchStatus () {
    //   this.STORE_GET(this.tableSearch)
    // },
    // tableSearchType () {
    //   this.STORE_GET(this.tableSearch)
    // },
    storeActive (v) {
      if (v) {
        this.STORE_GET(this.tableSearch)
      }
    }
  },
  mounted () {
    this.STORE_GET()
  },
  methods: {
    ProcessGallery (img, index) {
      if (img === 'delete') {
        if (!confirm('Delete this image ?')) {
          return false
        }
        this.dialogStore.data.galleries.splice(index, 1)
      } else {
        this.UploadImage('gallery', index || -1)
        // UPLOAD NEW IMAGE HERE
      }
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    STORE_SHOW (store) {
      this.areaselected = null
      this.areaquery = ''
      this.areas = []
      if (store) {
        this.dialogStore.data = Object.assign({}, store)
        this.dialogStore.data.galleries = this.$StrToArray(this.dialogStore.data.galleries)
        if (store.kecamatan_name) {
          this.areaquery = store.kecamatan_name + ', ' + store.city_type + ' ' + store.city_name + ', ' + store.province_name
        }
      } else {
        this.dialogStore.data = {
          id: null,
          type: '',
          type_main: '',
          type_sub: '',
          area: 0,
          lat: '',
          lng: '',
          logo: '',
          banner: '',
          name: '',
          description: '',
          galleries: [],
          phone: '',
          address: '',
          kecamatan_id: 0,
          featured: 1,
          price: 0,
          status: 1
        }
      }
      this.dialogStore.show = true
    },
    STORE_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      const type = '&type=' + (this.tableSearchType === 'All Type' ? '' : this.tableSearchType)
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('STORE', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + type).then((res) => {
        if (res.status) {
          if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
            this.table = res.data.data.filter(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
          } else {
            this.table = res.data.data
          }
          this.tableTotal = res.data.data.length || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    STORE_PROCESS () {
      const processedStore = Object.assign({}, this.dialogStore.data)
      processedStore.galleries = this.$ArrayToStr(processedStore.galleries || '[]')
      if (!processedStore.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (processedStore.type !== 'Doctors') {
        processedStore.type_main = ''
        processedStore.type_sub = ''
      } else if (processedStore.type_main === 'dr_spesialis' ? !processedStore.type_sub : !processedStore.type_main) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid doctors educations or specialist!' })
        return false
      }
      if (processedStore.type_main !== 'dr_spesialis') {
        processedStore.type_sub = ''
      }
      if (!this.areaselected) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select area!' })
        return false
      } else {
        const area = this.areaselected
        processedStore.kecamatan_id = area.kecamatan_id
        processedStore.city_id = area.city_id
        processedStore.province_id = area.province_id
      }
      if (!this.$validatePhone(processedStore.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No Telepon tidak valid!' })
        return false
      }
      processedStore.phone = this.$reformatPhone(processedStore.phone)
      this.$store.dispatch('STORE_PROCESS', processedStore)
        .then((res) => {
          if (res.status) {
            this.STORE_GET(this.tableSearch)
            this.dialogStore.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedStore.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage (type, index) {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, type, index)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, type, index) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: type || 'logo'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                if (type === 'banner') {
                  $.dialogStore.data.banner = resUpload.data.data.secure_url
                } else if (type === 'gallery') {
                  if (parseInt(index) > -1) {
                    $.dialogStore.data.galleries[index].src = resUpload.data.data.secure_url
                  } else {
                    $.dialogStore.data.galleries.push({ name: '', src: resUpload.data.data.secure_url })
                  }
                } else {
                  $.dialogStore.data.logo = resUpload.data.data.secure_url
                }
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
